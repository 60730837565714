import React from 'react';
import { asyncComponent } from '@jaredpalmer/after';

export default [
  {
    path: '/:lang?',
    exact: true,
    component: asyncComponent({
      loader: () => import('./App/Pages/Home'), 
      Placeholder: () => <div>...LOADING...</div>, 
    }),
  },
  {
    path: '/:lang?/:category?/view/:post',
    component: asyncComponent({
      loader: () => import('./App/Pages/PostsPage'), 
      Placeholder: () => <div>...LOADING...</div>, 
    }),
  },
  {
    path: '/:lang?/:category/page/:page',
    component: asyncComponent({
      loader: () => import('./App/Pages/PostsList'), 
      Placeholder: () => <div>...LOADING...</div>, 
    }),
  },
  {
    path: '/:lang?/:category?',
    component: asyncComponent({
      loader: () => import('./App/Pages/PostsList'), 
      Placeholder: () => <div>...LOADING...</div>, 
    }),
  },
  {
    component: asyncComponent({
      loader: () => import('./App/Pages/Error'), 
      Placeholder: () => <div>...LOADING...</div>, 
    }),
  }
];
